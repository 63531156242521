import React, { useState } from 'react';
import styled from 'styled-components';

const WaitlistContainer = styled.div`
  text-align: center;
  padding: 2rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  width: 300px;
  margin-right: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #1a73e8;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1558b1;
  }
`;

const WaitlistSection = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleJoinWaitlist = async () => {
    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbwkoGwFbb2JgM01x0zDITivodHL95cUsLvgHdMOMGEvnm5jxjE72nklE4zn4xGA-oMo/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      console.log('rahul', response);
      const result = await response.json();
      if (result.result === 'success') {
        setMessage("Thank you for joining the waitlist!");
        setEmail('');
      } else {
        setMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      setMessage("Error: Unable to join waitlist. Please try again later.");
    }
  };

  return (
    <WaitlistContainer>
      <h2>Join Our Waitlist</h2>
      <p>Stay updated and be the first to know when we launch!</p>
      <Input 
        type="email" 
        placeholder="Enter your email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />
      <Button onClick={handleJoinWaitlist}>Join Waitlist</Button>
      {message && <p>{message}</p>}
    </WaitlistContainer>
  );
};

export default WaitlistSection;
