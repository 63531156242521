import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyContainer = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 40px auto;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 30px;

  h1 {
    font-size: 2.5rem;
    color: #2c3e50;
  }

  p {
    font-size: 1rem;
    color: #7f8c8d;
  }
`;

const Section = styled.section`
  margin-bottom: 20px;

  h2 {
    font-size: 1.5rem;
    color: #34495e;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ul {
    margin-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
`;

const Footer = styled.footer`
  text-align: center;
  margin-top: 40px;

  p {
    font-size: 0.9rem;
    color: #95a5a6;
  }
`;

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <Header>
        <h1>Privacy Policy</h1>
        <p>Effective Date: November 25, 2024</p>
      </Header>

      <Section>
        <h2>Introduction</h2>
        <p>
          Welcome to GetCustomer.ai! We value your privacy and are committed to protecting your personal information.
          This Privacy Policy explains how we collect, use, and safeguard your data when you use our services.
        </p>
      </Section>

      <Section>
        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>Personal identification information (name, email address, phone number, etc.)</li>
          <li>Usage data and analytics about your interaction with our platform</li>
          <li>Technical data, such as IP address and browser type</li>
        </ul>
      </Section>

      <Section>
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li>To provide and maintain our services</li>
          <li>To improve user experience and platform performance</li>
          <li>To communicate with you regarding updates, promotions, and support</li>
        </ul>
      </Section>

      <Section>
        <h2>Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal information. If you have any questions or
          concerns about your privacy, please contact us at <a href="mailto:support@getcustomer.ai">support@getcustomer.ai</a>.
        </p>
      </Section>

      <Footer>
        <p>&copy; 2024 GetCustomer.ai. All rights reserved.</p>
      </Footer>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
