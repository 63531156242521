import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Routes, Route, useParams, Navigate } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import FeaturesSection from './components/FeaturesSection';
import WaitlistSection from './components/WaitlistSection';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy'; // Import PrivacyPolicy component

const AppContainer = styled.div`
  font-family: Arial, sans-serif;
`;

// Component to handle regex matching for privacy-related routes
const PrivacyRouteHandler = () => {
  const { wildcard } = useParams();
  const isPrivacyMatch = /^privacy/i.test(wildcard); // Match routes starting with "privacy"

  return isPrivacyMatch ? <PrivacyPolicy /> : <Navigate to="/" />; // Redirect to home if no match
};

function App() {
  return (
    <Router>
      <AppContainer>
        <Header />
        <Routes>
          {/* Main App Routes */}
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <AboutSection />
                <FeaturesSection />
                <WaitlistSection />
              </>
            }
          />

          {/* Privacy Regex Route */}
          <Route path="/:wildcard" element={<PrivacyRouteHandler />} />
        </Routes>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
